import { apiURL, backendAliveCheckInterval } from './config'

const fetchWithRetries = async (url, options, retryCount = 0) => {
    // split out the maxRetries option from the remaining
    // options (with a default of 3 retries)
    const { maxRetries = 5, ...remainingOptions } = options
    try {
        return await fetch(url, remainingOptions)
    } catch (error) {
        // if the retryCount has not been exceeded, call again
        if (retryCount < maxRetries) {
            // wait 2 seconds before retrying
            await new Promise((resolve) => setTimeout(resolve, backendAliveCheckInterval))

            // retry the request
            return fetchWithRetries(url, options, retryCount + 1)
        }
        // max retries exceeded
        throw error
    }
}

async function checkBackendAlive(bearerToken) {

    const res = await fetchWithRetries(
        `${apiURL}checkAlive`,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + bearerToken,
                'Content-Type': 'application/json',
            },
        },
        3
    )

    if (res.status === 200) {
        return true
    }

    return false
}

async function checkUserRegistered(bearerToken, setIsLoading, setPage) {

    const res = await fetchWithRetries(
        `${apiURL}users/checkUserData`,
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + bearerToken,
                'Content-Type': 'application/json',
            },
        },
        3
    )

    let bodyJson = await res.json()

    if (res.status === 200 && bodyJson.isRegistered === true) {
        return
    }

    if (res.status === 200 && bodyJson.isRegistered === false) {
        setIsLoading(false);
        setPage('register');
    }
}

async function storeUserData(bearerToken, userData, setPage, setIsLoading, setIsLoadingText, setJustRegistered) {

    setIsLoading(true);
    setIsLoadingText('Registering user...');

    const res = await fetchWithRetries(
        `${apiURL}users/storeUserData`,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + bearerToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData)
        },
        3
    )

    if (res.status === 200) {
        await new Promise((resolve) => setTimeout(resolve, 2000))
        setIsLoading(false);
        setJustRegistered(true);
        setPage('main');
    }

    if (res.status !== 200) {
        setIsLoading(false);
        alert("Something went wrong. Please try again.")
    }
}

export { checkBackendAlive, checkUserRegistered, storeUserData }