import { apiURL } from "../../utils/config"

export default function ThreeJsViewer({visible, userName, envType}) {

    if (!visible) {
        return null
    }

    return (
        <>
            <div
                style={{
                    width: "100vw",
                    height: "calc(100vh - 64px)",
                    position: "absolute",
                    top: "64px",
                    left: "0px",
                    overflowX: "hidden",
                    overflowY: "hidden",
                }}
            >
                <iframe
                    title="application"
                    src={"/main.html?apiURL=" + apiURL + "&userName=" + userName+ "&envType=" + envType}
                    style={{
                        width: "100vw",
                        height: "100vh",
                        overflow: "hidden",
                        display: "block",
                        border: "none",
                        margin: "0px",
                    }}
                    sandbox='allow-scripts allow-same-origin allow-forms allow-downloads allow-modals'
                />
            </div>

            {/* <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
                <div className="pointer-events-auto ml-auto max-w-sm rounded-xl bg-black p-6 shadow-lg ring-1 ring-gray-900/10">
                    <p className="text-md leading-6 text-white">
                    How can we improve?
                    </p>
                    <div className="mt-4 flex items-center gap-x-5">
                    <button
                        type="button"
                        className="rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >
                        Fill out a short survey
                    </button>
                    <button type="button" 
                        className="rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-800"
                    >
                        Not now
                    </button>
                    </div>
                </div>
            </div>   */}
        </>      
    )
}