import { FrontendApi, Configuration } from "@ory/client"

import { basePath, oryJwtTokenTtl, oryJwtTokenizer } from "./config"
import { checkBackendAlive, checkUserRegistered } from "./backend"

const ory = new FrontendApi(
    new Configuration({
        basePath,
        baseOptions: {
            withCredentials: true,
        },
    }),
)

function handleAuth(setSession, setCookie, setLogoutUrl, setIsLoading, setIsLoadingText, page, setPage, justRegistered) {

    setIsLoadingText("Authenticating...")

    ory
        .toSession({
            tokenizeAs: oryJwtTokenizer,
        })
        .then(({ data }) => {
            // User has a session!
            setSession(data)

            let expires = new Date()
            expires.setTime(expires.getTime() + oryJwtTokenTtl * 60 * 1000)
            setCookie(
                'access_token', 
                data.tokenized, 
                { 
                    path: '/', 
                    expires, 
                    secure: true, 
                    sameSite: 'strict' 
                }
            )

            ory.createBrowserLogoutFlow().then(({ data }) => {
                // Get also the logout url
                setLogoutUrl(data.logout_url)
            })

            setIsLoadingText("Connecting to backend...")

            checkBackendAlive(data.tokenized)
                .then(x => {
                    setIsLoading(false)
                    setIsLoadingText("Loading...")
                    
                    if (!x) {
                        alert("Cannot access backend. Please try again later...")
                    }
                })
                .catch(err => {
                    console.error(err)
                    setIsLoading(false)
                    setIsLoadingText("Loading...")
                    alert("Cannot access backend. Please try again later...")
                })
            
            if (page !== "register" && !justRegistered) {
                data && data.tokenized && checkUserRegistered(data.tokenized, setIsLoading, setPage);
            }
        })
        .catch((err) => {
            console.error(err)
            // Redirect to login page
            window.location.replace(`${basePath}/ui/login`)
        })
};

export { ory, handleAuth }